import { Market, Genre, Campaign, CampaignGenre } from "../types";
import { CampaignActionTypes } from "../actions/campaign";
import { SET_MEDIA_BRIEF, SET_MARKET_DEFINITION, SET_CAMPAIGN_DATE, SELECT_OTHER_MARKET, SET_KEY_OTHER_MARKET, GET_BRAND_FOR_CAMPAIGN, GET_MARKETS, GET_GENRES, SET_KEY_MARKETS, SET_GENRES, SET_CONCERNS, ADD_CAMPAIGN, REMOVE_CAMPAIGN, RESET_CAMPAIGNS } from "../actions/types";

export interface CampaignState {
  loading: boolean,
  campaigns: Campaign[],
  campaign: Campaign,
  markets: Market[],
  genres: Genre[]
}

const initialState: CampaignState = {
  loading: false,
  campaigns: [],
  campaign: {} as Campaign,
  markets: [],
  genres: []
}

export default function (state = initialState, action: CampaignActionTypes) {
  switch (action.type) {
    case ADD_CAMPAIGN:
      return { ...state, campaigns: [...state.campaigns, state.campaign], campaign: {...state.campaign, marketDefinition: ''}}
    case REMOVE_CAMPAIGN:
      return { ...state, campaigns: state.campaigns.filter(campaign => campaign.brand_id !== action.brand_id) };
    case GET_BRAND_FOR_CAMPAIGN:
      const campaign = state.campaigns.find(item => item.brand_id === action.brand.brand_id);
      if (campaign) {
        return {
          ...state, campaign,
          markets:
            state.markets.map(market =>
              campaign.keyMarkets && campaign.keyMarkets.find(el => el.id === market.id) ? { ...market, checked: true } : market),
          genres:
            state.genres.map(genre =>
              campaign.genres.find(el => el.id === genre.id) ? { ...genre, checked: true } : genre)
        };
      }
      const newCampaign = {} as Campaign;
      return {
        ...state,
        campaign: { ...newCampaign, brand_id: action.brand.brand_id, brand_name: action.brand.brand_name, client_name: action.brand.client_name, target_group: action.brand.target_group },
        markets: state.markets.map(market => ({ ...market, checked: false })),
        genres: state.genres.map(genre => ({ ...genre, checked: false }))
      };
    case SET_MEDIA_BRIEF:
      return { ...state, campaign: { ...state.campaign, mediaBrief: action.brief } }
    case SET_MARKET_DEFINITION:
      return { ...state, campaign: { ...state.campaign, marketDefinition: action.definition } }
    case SET_CAMPAIGN_DATE:
      return { ...state, campaign: { ...state.campaign, campaignDate: action.campaignDate } }
    case SELECT_OTHER_MARKET:
      return { ...state, campaign: { ...state.campaign, otherMarketChecked: action.checked } }
    case SET_KEY_OTHER_MARKET:
      return { ...state, campaign: { ...state.campaign, otherMarket: action.other } }
    case GET_MARKETS:
      return { ...state, markets: action.markets, loading: false };
    case GET_GENRES:
      return { ...state, genres: action.genres, loading: false };
    case SET_KEY_MARKETS:
      const markets = state.markets.map(market =>
        market.id === action.payload.id ? { ...market, checked: action.payload.checked } : market);
      return { ...state, markets, campaign: { ...state.campaign, keyMarkets: markets.filter(market => market.checked) } };
    case SET_GENRES:
      const genres = state.genres.map(genre =>
        genre.id === action.payload.id ? { ...genre, checked: action.payload.checked } : genre);
      const selectedGenre = state.genres.find(genre => genre.id === action.payload.id);
      if (action.payload.checked) {
        return { ...state, genres, campaign: { ...state.campaign, genres: state.campaign.genres ? [...state.campaign.genres, selectedGenre as CampaignGenre] : [selectedGenre as CampaignGenre] } };
      } else {
        return { ...state, genres, campaign: { ...state.campaign, genres: state.campaign.genres ? state.campaign.genres.filter(genre => genre.id !== action.payload.id) : [] } }
      }
    case SET_CONCERNS:
      return {
        ...state, campaign: {
          ...state.campaign,
          genres: state.campaign.genres && state.campaign.genres.map(genre =>
            genre.id === action.payload.id ? { ...genre, concern: action.payload.concern } : genre)
        }
      }
    case RESET_CAMPAIGNS:
      return {
        ...state, campaigns: [], campaign: {} as Campaign
      }
    default:
      return state;
  }
}