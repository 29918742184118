import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonButtons, IonBackButton, IonSpinner } from '@ionic/react';
import React, { useEffect } from 'react';
import DsrListContainer from '../components/DsrListContainer';
import { useDispatch, useSelector } from 'react-redux';
import { getAllDsrs } from '../actions/review';
import { RootState } from '../reducers';

const DsrList: React.FC = () => {
  const dispatch = useDispatch();
  const user_id = useSelector<RootState, string>(state => state.user.user_id);
  const loading = useSelector<RootState, boolean>(state => state.review.loading);

  useEffect(() => {
    dispatch(getAllDsrs(user_id));
    // eslint-disable-next-line
  }, []);

  return (
    <IonPage>
      <IonHeader translucent={true} collapse='fade'>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="home" />
          </IonButtons>
          <IonTitle>List DSRs</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">List DSRs</IonTitle>
          </IonToolbar>
        </IonHeader>
        {loading ? <IonSpinner /> : <DsrListContainer />}
      </IonContent>
    </IonPage>
  );
};

export default DsrList;
