import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonButtons, IonBackButton, IonSpinner, IonLabel, IonItem } from '@ionic/react';
import React, { useEffect } from 'react';
import DsrDetailContainer from '../components/DsrDetailContainer';
import { useDispatch, useSelector } from 'react-redux';
import { getDsrDetails } from '../actions/review';
import { useParams } from 'react-router-dom';
import { RootState } from '../reducers';
import { DsrDetail } from '../types';

interface ParamTypes {
  dsr_id: string
}

const DsrDetailPage: React.FC = () => {
  const dispatch = useDispatch();
  const loading = useSelector<RootState, boolean>(state => state.review.loading);
  const dsrDetail = useSelector<RootState, DsrDetail>(state => state.review.dsrDetail);

  const { dsr_id } = useParams<ParamTypes>();

  useEffect(() => {
    if (dsr_id) {
      dispatch(getDsrDetails(dsr_id));
    }
    // eslint-disable-next-line
  }, []);

  return (
    <IonPage>
      <IonHeader translucent={true} collapse='fade'>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/dsrs" />
          </IonButtons>
          <IonTitle>DSR Detail</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">DSR Detail</IonTitle>
          </IonToolbar>
        </IonHeader>
        {loading ? (<IonSpinner />) :
          dsrDetail ? (
            <DsrDetailContainer dsr={dsrDetail} />
          ) : (
              <IonItem lines='none' className='ion-text-center'>
                <IonLabel>DSR with id {dsr_id} not found</IonLabel>
              </IonItem>
            )}
      </IonContent>
    </IonPage>
  );
};

export default DsrDetailPage;
