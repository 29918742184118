import React from 'react';
import './LoginContainer.css';
import { IonList, IonItem, IonLabel, IonInput, IonText } from '@ionic/react';
import { useSelector, useDispatch } from 'react-redux';
import { setLoginFormValueAction } from '../actions/user';
import { RootState } from '../reducers';
import { LoginForm } from '../types';

const LoginContainer: React.FC = () => {

  const loginForm = useSelector<RootState, LoginForm>(state => state.user.loginForm);
  const dispatch = useDispatch();

  const onChange = (e: any) => {
    dispatch(setLoginFormValueAction(e.target.name, e.detail.value));
  };

  return (
    <IonList>
      <IonItem>
        <IonLabel position="stacked">Email Id<IonText color='danger'>*</IonText></IonLabel>
        <IonInput type="email" name="email_id" title='Email Id' value={loginForm.email_id}
          onIonChange={e => onChange(e)}></IonInput>
      </IonItem>
      <IonItem>
        <IonLabel position="stacked">Mobile No<IonText color='danger'>*</IonText></IonLabel>
        <IonInput type="number" name="phone_number" title='Mobile No' value={loginForm.phone_number}
          onIonChange={e => onChange(e)}></IonInput>
      </IonItem>
      <IonItem>
        <IonLabel position="stacked">Password<IonText color='danger'>*</IonText></IonLabel>
        <IonInput type="password" name="password" title='Password' value={loginForm.password}
          onIonChange={e => onChange(e)}></IonInput>
      </IonItem>
    </IonList>
  );
};

export default LoginContainer;

