import React, { useState } from 'react';
import { IonItem, IonList, IonSearchbar, IonLabel, IonCheckbox, IonButton, IonIcon, IonItemSliding, IonItemOptions, IonItemOption } from '@ionic/react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { personAdd, createOutline } from 'ionicons/icons';
import { setDsrContactAction } from '../actions/dsr';
import { ClientContact, AgencyContact } from '../types';

interface ContainerProps {
  agency: boolean,
  contacts: (ClientContact | AgencyContact)[],
}

const SearchContactContainer: React.FC<ContainerProps> = ({ agency, contacts }) => {

  const dispatch = useDispatch();
  const history = useHistory();
  const [searchText, setSearchText] = useState('');

  return (
    <>
      <IonItem lines='none'>
        <IonSearchbar value={searchText} placeholder='Filter'
          onIonChange={e => setSearchText(e.detail.value!)}></IonSearchbar>
      </IonItem>
      <IonItem lines='none'>
        <IonButton fill='clear' onClick={() => agency ? history.push('/agencies/add-contacts') : history.push('/clients/add-contacts')}><IonIcon slot='start' icon={personAdd}></IonIcon>Add New Contact</IonButton>
      </IonItem>
      <IonList>
        {contacts.filter(contact => searchText ? (contact.contact_name.toUpperCase().indexOf(searchText.toUpperCase()) > -1 || contact.designation.toUpperCase().indexOf(searchText.toUpperCase()) > 0) : true).map(contact => (
          <IonItemSliding key={(contact as AgencyContact).agency_contact_id ? (contact as AgencyContact).agency_contact_id : (contact as ClientContact).client_contact_id}>
            <IonItemOptions side="end">
              <IonItemOption onClick={() => (contact as AgencyContact).agency_contact_id ? history.push(`/agencies/contacts/edit/${(contact as AgencyContact).agency_contact_id}`) : history.push(`/clients/contacts/edit/${(contact as ClientContact).client_contact_id}`)}>
                <IonIcon slot="start" icon={createOutline}></IonIcon>
              Edit</IonItemOption>
            </IonItemOptions>
            <IonItem lines='none'>
              <IonLabel className='ion-text-wrap'>{contact.contact_name} - {contact.designation}</IonLabel>
              <IonCheckbox slot="start" onIonChange={e => dispatch(setDsrContactAction(e.detail.checked, contact))} />
            </IonItem>
          </IonItemSliding>
        ))}
      </IonList>
    </>
  );
};

export default SearchContactContainer;