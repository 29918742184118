import React, { useState } from 'react';
import './LoginContainer.css';
import { IonItem, IonLabel, IonInput, IonRadioGroup, IonRadio, IonButton, IonModal, IonIcon, IonText } from '@ionic/react';
import { useDispatch, useSelector } from 'react-redux';
import { setBrandNameAction, setBrandClientIdAction, setTargetGroupAction } from '../actions/brand';
import { DEFAULT_TARGET_GROUPS } from '../constants';
import TargetGroupModal from './TargetGroupModal';
import { paperPlaneOutline } from 'ionicons/icons';
import { Brand, Client } from '../types';
import { RootState } from '../reducers';

interface ContainerProps {
  tgOnly: boolean,
  agency: boolean,
}
const BrandContainer: React.FC<ContainerProps> = ({ agency, tgOnly }) => {

  const [showModal, setShowModal] = useState(false);

  const dispatch = useDispatch();
  const brand = useSelector<RootState, Brand>(state => state.brand.brand);
  const clients = useSelector<RootState, Client[]>(state => state.dsr.dsrAgencyClients);

  async function closeModal() {
    await setShowModal(false);
  }

  return (
    <React.Fragment>
      <IonItem className='ion-padding'>
        <IonLabel position="stacked">Brand Name<IonText color='danger'>*</IonText></IonLabel>
        <IonInput type="text" readonly={tgOnly} name="brand_name" value={brand.brand_name}
          onIonChange={e => { dispatch(setBrandNameAction(e.detail.value!)) }}></IonInput>
      </IonItem>

      <IonModal isOpen={showModal} className="fullscreen" onDidDismiss={() => setShowModal(false)}>
        <TargetGroupModal onClose={closeModal}></TargetGroupModal>
      </IonModal>

      <IonItem lines='none' className='ion-padding'>
        <IonLabel>Buying TG<IonText color='danger'>*</IonText></IonLabel>
        <IonButton fill='clear' onClick={() => setShowModal(true)}><IonIcon slot='start' icon={paperPlaneOutline}></IonIcon>
        Add New Buying TG
      </IonButton>
      </IonItem>

      <IonRadioGroup value={brand.target_group} onIonChange={e => dispatch(setTargetGroupAction(e.detail.value))}>
        {DEFAULT_TARGET_GROUPS.map(tg =>
          <IonItem lines='none' key={tg}>
            <IonLabel>{tg}</IonLabel>
            <IonRadio mode='md' slot="start" value={tg} />
          </IonItem>)
        }
        {brand.target_group && DEFAULT_TARGET_GROUPS.indexOf(brand.target_group) < 0 &&
          <IonItem lines='none'>
            <IonLabel>{brand.target_group}</IonLabel>
            <IonRadio mode='md' slot="start" value={brand.target_group} />
          </IonItem>
        }
      </IonRadioGroup>

      {!tgOnly && agency && (
        <>
          <IonItem>
            <IonLabel>Select a client for the brand</IonLabel>
          </IonItem>
          <IonRadioGroup value={brand.client_id} onIonChange={e => dispatch(setBrandClientIdAction(e.detail.value))}>
            {clients.map(client =>
              <IonItem key={client.client_id}>
                <IonLabel>{client.client_name}</IonLabel>
                <IonRadio mode='md' slot="end" value={client.client_id} />
              </IonItem>)
            }
          </IonRadioGroup>
          <IonItem><IonLabel>&nbsp;</IonLabel></IonItem>
        </>
      )}
    </React.Fragment>
  );
};

export default BrandContainer;

