import React from 'react';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonFooter, IonButton, IonIcon } from '@ionic/react';
import './Login.css';
import LoginContainer from '../components/LoginContainer';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { login, resendPassword } from '../actions/user';
import { setAlert } from '../actions/alert';
import { paperPlaneOutline } from 'ionicons/icons';
import { RootState } from '../reducers';
import { LoginForm } from '../types';

const Login: React.FC = () => {

  const dispatch = useDispatch();
  const token = useSelector<RootState, string>(state => state.user.token);
  const loginForm = useSelector<RootState, LoginForm>(state => state.user.loginForm);

  if (token) {
    return <Redirect to='/home' />;
  }

  const onSubmit = () => {
    if (loginForm.email_id && loginForm.phone_number && loginForm.password) {
      dispatch(login(loginForm));
    } else {
      dispatch(setAlert('All fields are required', 'danger'));
    }
  };

  const onResendPassword = () => {
    if (loginForm.email_id && loginForm.phone_number) {
      dispatch(resendPassword(loginForm));
    } else {
      dispatch(setAlert('Email and phone number required', 'danger'));
    }
  }

  return (
    <IonPage>
      <IonHeader translucent>
        <IonToolbar>
          <IonTitle>Login</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Login</IonTitle>
          </IonToolbar>
        </IonHeader>
        <LoginContainer />
      </IonContent>
      <IonFooter>
        <IonButton fill='clear' onClick={() => onResendPassword()}>
          <IonIcon slot='start' icon={paperPlaneOutline}></IonIcon>
          Resend Password
        </IonButton>
        <IonButton expand='block' onClick={() =>
          onSubmit()
        }>Submit</IonButton>
      </IonFooter>
    </IonPage>
  );
};

export default Login;