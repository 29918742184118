import React, { useState } from 'react';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonFooter, IonButton, IonInput, IonItem, IonLabel, IonList, IonText, IonBackButton, IonButtons } from '@ionic/react';
import './Login.css';
import { useDispatch } from 'react-redux';
import { changePassword } from '../actions/user';
import { setAlert } from '../actions/alert';
import { useHistory } from 'react-router';

const ChangePassword: React.FC = () => {

  const dispatch = useDispatch();
  const history = useHistory();
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const onSubmit = () => {
    if (!currentPassword || !newPassword || !confirmPassword) {
      dispatch(setAlert('All fields required', 'danger'));
    } else if (newPassword !== confirmPassword) {
      dispatch(setAlert('New password and confirm password must match', 'danger'));
    } else if (currentPassword === newPassword) {
      dispatch(setAlert('New password cannot be the same as old password', 'danger'));
    } else {
      dispatch(changePassword(currentPassword, newPassword));
      history.go(-1);
    }
  };

  return (
    <IonPage>
      <IonHeader translucent={true} collapse='fade'>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="../home" />
          </IonButtons>
          <IonTitle>Change Password</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Change Password</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonList>
      <IonItem>
        <IonLabel position="stacked">Current Password<IonText color='danger'>*</IonText></IonLabel>
        <IonInput type="password" name="currentPassword" title='Current Password' value={currentPassword}
          onIonChange={e => e.detail.value && setCurrentPassword(e.detail.value)}></IonInput>
      </IonItem>
      <IonItem>
        <IonLabel position="stacked">New Password<IonText color='danger'>*</IonText></IonLabel>
        <IonInput type="password" name="newPassword" title='New Password' value={newPassword}
          onIonChange={e => e.detail.value && setNewPassword(e.detail.value)}></IonInput>
      </IonItem>
      <IonItem>
        <IonLabel position="stacked">Confirm Password<IonText color='danger'>*</IonText></IonLabel>
        <IonInput type="text" name="confirmPassword" title='Confirm Password' value={confirmPassword}
          onIonChange={e => e.detail.value && setConfirmPassword(e.detail.value)}></IonInput>
      </IonItem>
    </IonList>
      </IonContent>
      <IonFooter>
        <IonButton expand='block' onClick={() =>
          onSubmit()
        }>Change Password</IonButton>
      </IonFooter>
    </IonPage>
  );
};

export default ChangePassword;