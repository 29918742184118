import axios from 'axios';
import { LOGIN_SUCCESS, LOGIN_FAIL, SEARCH_USERS_SUCCESS, SEARCH_USERS_FAIL, RESEND_PASSWORD, RESEND_PASSWORD_FAIL, SELECT_USER, DESELECT_USER, SET_LOGIN_FORM_VALUE, LOGOUT, CHANGE_PASSWORD } from './types';
import { setAlert } from './alert';
import { API_ENDPOINT } from '../constants';
import { User, LoginForm } from '../types';
import { AppThunk } from '../store';
import { setAuthToken } from '../utils/setCommonHeaders';

interface LoginSuccessAction {
  type: typeof LOGIN_SUCCESS,
  uid: string,
  token: string
}

interface LoginFailAction {
  type: typeof LOGIN_FAIL
}

interface ResendPasswordAction {
  type: typeof RESEND_PASSWORD
}

interface ResendPasswordFailAction {
  type: typeof RESEND_PASSWORD_FAIL
}

interface SearchUsersAction {
  type: typeof SEARCH_USERS_SUCCESS,
  users: User[]
}

interface SearchUsersFailAction {
  type: typeof SEARCH_USERS_FAIL
}

interface SelectUserAction {
  type: typeof SELECT_USER,
  user: User
}

interface DeselectUserAction {
  type: typeof DESELECT_USER,
  user: User
}

interface SetLoginFormValue {
  type: typeof SET_LOGIN_FORM_VALUE,
  payload: { field: string, value: string }
}

interface LogoutAction {
  type: typeof LOGOUT
}

interface ChangePasswordAction {
  type: typeof CHANGE_PASSWORD
}

export type UserActionTypes = LoginSuccessAction | LoginFailAction | ResendPasswordAction
  | ResendPasswordFailAction | SearchUsersAction | SearchUsersFailAction | SelectUserAction
  | DeselectUserAction | SetLoginFormValue | LogoutAction | ChangePasswordAction;

const loginAction = (uid: string, token: string): UserActionTypes => {
  return {
    type: LOGIN_SUCCESS,
    uid,
    token
  }
}

const loginFailAction = (): UserActionTypes => {
  return { type: LOGIN_FAIL }
}

const resendPasswordAction = (): UserActionTypes => {
  return { type: RESEND_PASSWORD }
}

const resendPasswordFailAction = (): UserActionTypes => {
  return { type: RESEND_PASSWORD_FAIL }
}

const searchUsersAction = (users: User[]): UserActionTypes => {
  return {
    type: SEARCH_USERS_SUCCESS,
    users
  }
}

const searchUsersFailAction = (): UserActionTypes => {
  return { type: SEARCH_USERS_FAIL }
}

export const selectUserAction = (user: User): UserActionTypes => {
  return {
    type: SELECT_USER,
    user
  }
}

export const deselectUserAction = (user: User): UserActionTypes => {
  return {
    type: DESELECT_USER,
    user
  }
}

export const setLoginFormValueAction = (field: string, value: string): UserActionTypes => {
  return {
    type: SET_LOGIN_FORM_VALUE,
    payload: { field, value }
  }
}

export const logoutAction = (): UserActionTypes => {
  return {
    type: LOGOUT
  }
}

export const changePasswordAction = (): UserActionTypes => {
  return {
    type: CHANGE_PASSWORD
  }
}

export const login = (loginForm: LoginForm): AppThunk => async dispatch => {
  const config = {
    headers: {
      'Content-type': 'application/json'
    }
  };
  try {
    const res = await axios.post(`${API_ENDPOINT}/users/check`, loginForm, config);
    if (res.data.status) {
      localStorage.setItem('user_id', res.data.uid);
      localStorage.setItem('token', res.data.token);
      setAuthToken(res.data.token);
      dispatch(loginAction(res.data.uid, res.data.token));
    } else {
      dispatch(setAlert(res.data.message, 'danger'));
      localStorage.removeItem('user_id');
      localStorage.removeItem('token');
      dispatch(loginFailAction());
    }
  } catch (err) {
    console.log(err);
    dispatch(setAlert('Unknown error occurred. Plesae retry', 'danger'));
    localStorage.removeItem('user_id');
    localStorage.removeItem('token');
    dispatch(loginFailAction());
  }
};

export const resendPassword = (loginForm: LoginForm): AppThunk => async dispatch => {
  const config = {
    headers: {
      'Content-type': 'application/json'
    }
  };
  try {
    const res = await axios.post(`${API_ENDPOINT}/users/resend_password`, loginForm, config);

    if (res.data.status) {
      dispatch(resendPasswordAction());
      dispatch(setAlert('Password resent. Please provide and login', 'success'));
    } else {
      dispatch(setAlert(res.data.message, 'danger'));
      dispatch(resendPasswordFailAction());
    }
  } catch (err) {
    console.log(err);
    if (err.msg) {
      dispatch(setAlert(err.msg, 'danger'));
    } else {
      dispatch(setAlert('Unknown error occurred. Plesae retry', 'danger'));
    }
    dispatch(resendPasswordFailAction());
  }
}

export const searchUsers = (name: string, user_id: string): AppThunk => async dispatch => {
  const body = { name, user_id };
  try {
    const res = await axios.get(`${API_ENDPOINT}/users/search`, {
      params: body
    });

    if (res.data.status) {
      const users = res.data.message;
      if (users.length > 0) {
        dispatch(searchUsersAction(res.data.message));
      } else {
        dispatch(setAlert('No users match search term', 'danger'));
      }
    } else {
      dispatch(setAlert(res.data.message, 'danger'));
      dispatch(searchUsersFailAction());
    }
  } catch (err) {
    console.log(err);
    if (err.msg) {
      dispatch(setAlert(err.msg, 'danger'));
    } else {
      dispatch(setAlert('Unknown error occurred. Plesae retry', 'danger'));
    }
    dispatch(searchUsersFailAction());
  }
};

export const performLogout = (): AppThunk => dispatch => {
  localStorage.removeItem('token');
  localStorage.removeItem('user_id');
  setAuthToken('');
  dispatch(logoutAction());
}

export const changePassword = (password: String, newPassword: String): AppThunk => async dispatch => {
  const config = {
    headers: {
      'Content-type': 'application/json'
    }
  };
  const changePasswordForm = {
    current_password: password,
    new_password: newPassword
  }
  try {
    const res = await axios.post(`${API_ENDPOINT}/users/change_password`, changePasswordForm, config);

    if (res.data.status) {
      dispatch(changePasswordAction());
      dispatch(setAlert('Password reset successfully', 'success'));
    } else {
      dispatch(setAlert(res.data.message, 'danger'));
    }
  } catch (err) {
    console.log(err);
    if (err.msg) {
      dispatch(setAlert(err.msg, 'danger'));
    } else {
      dispatch(setAlert('Unknown error occurred. Plesae retry', 'danger'));
    }
  }
}