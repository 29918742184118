import {
  IonMenu,
  IonContent,
  IonList,
  IonListHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonMenuToggle,
  IonAlert,
} from "@ionic/react";
import { lockOpenOutline, lockOpenSharp, logOutOutline, logOutSharp } from "ionicons/icons";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { performLogout } from "../actions/user";
import "./Menu.css";

const Menu: React.FC = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [showAlert, setShowAlert] = useState(false);

  return (
    <IonMenu contentId="main" type="overlay" side="end">
      <IonContent>
        <IonList id="inbox-list">
          <IonListHeader>Welcome</IonListHeader>
          <IonMenuToggle autoHide={false}>
            <IonItem
              className={
                location.pathname === "/password-change" ? "selected" : ""
              }
              routerLink="/password-change"
              routerDirection="none"
              lines="none"
              detail={false}
            >
              <IonIcon color="primary" slot="start" ios={lockOpenOutline} md={lockOpenSharp} />
              <IonLabel>Change Password</IonLabel>
            </IonItem>
          </IonMenuToggle>
          <IonMenuToggle autoHide={false}>
            <IonItem onClick={() => setShowAlert(true)} lines='none'>
              <IonLabel>Logout</IonLabel>
              <IonIcon color="primary" slot="start" ios={logOutOutline} md={logOutSharp} />
            </IonItem>
          </IonMenuToggle>
        </IonList>
        <IonAlert
          isOpen={showAlert}
          onDidDismiss={() => setShowAlert(false)}
          cssClass="my-custom-class"
          subHeader={"Logout"}
          message={"Confirm logout?"}
          buttons={[
            {
              text: "Ok",
              handler: () => {
                dispatch(performLogout());
              },
            },
            {
              text: "Cancel",
              role: "cancel",
            },
          ]}
        />
      </IonContent>
    </IonMenu>
  );
};

export default Menu;
