import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonButtons, IonBackButton, IonFooter, IonButton } from '@ionic/react';
import React from 'react';
import SearchAgencyContainer from '../components/SearchAgencyContainer';
import { Redirect, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setAlert } from '../actions/alert';
import { rememberRecentAgencyAction } from '../actions/agency';
import { RootState } from '../reducers';
import { Agency } from '../types';
import { getContacts } from '../actions/contact';
import { resetDsrContacts } from '../actions/dsr';

const SearchAgency: React.FC = () => {

  const dispatch = useDispatch();
  const dsrAgency = useSelector<RootState, Agency>(state => state.dsr.dsrAgency);
  const dsrDate = useSelector<RootState, string>(state => state.dsr.dsrDate);
  const history = useHistory();

  if (!dsrDate) {
    return <Redirect to='/home' />;
  }

  const validateAndProceed = () => {
    if (dsrAgency.agency_id) {
      dispatch(rememberRecentAgencyAction(dsrAgency));
      dispatch(getContacts(`${dsrAgency.agency_id}`, true));
      dispatch(resetDsrContacts());
      history.push(`/agencies/clients/${dsrAgency.agency_id}`);
    } else {
      dispatch(setAlert('Please select an agency to proceed', 'danger'));
    }
  }

  return (
    <IonPage>
      <IonHeader translucent>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="home" />
          </IonButtons>
          <IonTitle>Search Agency</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Search Agency</IonTitle>
          </IonToolbar>
        </IonHeader>
        <SearchAgencyContainer />
      </IonContent>
      <IonFooter>
        <IonButton expand='block' onClick={() =>
          validateAndProceed()
        }>Proceed</IonButton>
      </IonFooter>
    </IonPage>
  );
};


export default SearchAgency;
