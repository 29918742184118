import React from 'react';
import { IonItem, IonButton, IonIcon, IonSpinner, IonList, IonLabel, IonRadio, IonRadioGroup, IonItemSliding, IonItemOptions, IonItemOption, IonListHeader, IonText } from '@ionic/react';
import { searchAgencies } from '../actions/agency';
import { setDsrAgencyAction } from '../actions/dsr';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { createOutline, personAdd } from 'ionicons/icons';
import Search from './Search';
import { RootState } from '../reducers';
import { Agency } from '../types';


const SearchAgencyContainer: React.FC = () => {

  const dispatch = useDispatch();
  const dsrAgency = useSelector<RootState, Agency>(state => state.dsr.dsrAgency);
  const loading = useSelector<RootState, boolean>(state => state.agency.loading);
  const agencies = useSelector<RootState, Agency[]>(state => state.agency.agencies);
  const recentAgencies = useSelector<RootState, Agency[]>(state => state.agency.recentAgencies);

  const history = useHistory();

  const onAgencySelected = (id: number) => {
    const selectedAgencies = agencies.find(agency => agency.agency_id === id);
    selectedAgencies && dispatch(setDsrAgencyAction(selectedAgencies));
  }

  const onRecentAgencySelected = (id: number) => {
    const selectedAgencies = recentAgencies.find(agency => agency.agency_id === id);
    selectedAgencies && dispatch(setDsrAgencyAction(selectedAgencies));
  }

  return (
    <>
      <Search placeholder='Agencies' searchAction={searchAgencies} />
      <IonItem lines='none'>
        <IonButton fill='clear' onClick={() => history.push('/agencies/add')}><IonIcon slot='start' icon={personAdd}></IonIcon>Add New Agency</IonButton>
      </IonItem>
      {loading && agencies === null ? (
        <IonSpinner />
      ) : (
          <IonList className="ion-padding">
            <IonRadioGroup value={dsrAgency?.agency_id} onIonChange={e => onAgencySelected(e.detail.value)}>
              {agencies.map(agency => (
                <IonItemSliding key={agency.agency_id}>
                  <IonItemOptions side="end">
                    <IonItemOption onClick={() => history.push(`/agencies/edit/${agency.agency_id}`)}>
                      <IonIcon slot="start" icon={createOutline}></IonIcon>
                Edit</IonItemOption>
                  </IonItemOptions>
                  <IonItem lines='none'>
                    <IonLabel>{agency.agency_name}</IonLabel>
                    <IonRadio mode='md' slot="start" value={agency.agency_id} />
                  </IonItem>
                </IonItemSliding>
              ))}
            </IonRadioGroup>
          </IonList>
        )}
      <IonList className='ion-padding'>
        <IonListHeader>
          <IonLabel><IonText><h2>Recently Used</h2></IonText></IonLabel>
        </IonListHeader>
        <IonRadioGroup value={dsrAgency?.agency_id} onIonChange={e => onRecentAgencySelected(e.detail.value)}>
          {recentAgencies.map(agency => (
            <IonItemSliding key={agency.agency_id}>
              <IonItemOptions side="end">
                <IonItemOption onClick={() => history.push(`/agencies/edit/${agency.agency_id}`)}>
                  <IonIcon slot="start" icon={createOutline}></IonIcon>
                Edit</IonItemOption>
              </IonItemOptions>
              <IonItem lines='none'>
                <IonLabel>{agency.agency_name}</IonLabel>
                <IonRadio mode='md' slot="start" value={agency.agency_id} />
              </IonItem>
            </IonItemSliding>
          ))}
        </IonRadioGroup>
      </IonList>
    </>
  );
};

export default SearchAgencyContainer;