import React, { useState } from 'react';
import './LoginContainer.css';
import { IonItem, IonList, IonLabel, IonInput, IonDatetime, IonSelect, IonSelectOption, IonRadioGroup, IonSpinner, IonRadio, IonText } from '@ionic/react';
import { useDispatch, useSelector } from 'react-redux';
import { searchClients } from '../actions/client';
import { searchAgencies } from '../actions/agency';
import { TITLES } from '../constants';
import Search from './Search';
import { SET_CONTACT_FORM_VALUE } from '../actions/types';
import { ClientContact, AgencyContact, Client, Agency } from '../types';
import { RootState } from '../reducers';
import { format, subYears } from 'date-fns';

interface ContainerProps {
  edit: boolean,
  isAgency: boolean
}

const ContactContainer: React.FC<ContainerProps> = ({ edit, isAgency }) => {

  const dispatch = useDispatch();
  const [showBirthday, setShowBirthday] = useState(false);
  const [showAnniversary, setAnniversary] = useState(false);

  const contact = useSelector<RootState, ClientContact | AgencyContact>(state => state.contact.contact);
  const clients = useSelector<RootState, Client[]>(state => state.client.clients);
  const clientLoading = useSelector<RootState, boolean>(state => state.client.loading);
  const agencies = useSelector<RootState, Agency[]>(state => state.agency.agencies);
  const agencyLoading = useSelector<RootState, boolean>(state => state.agency.loading);

  const onChange = (e: any) => {
    setShowBirthday(false);
    setAnniversary(false);
    dispatch({ type: SET_CONTACT_FORM_VALUE, payload: { field: e.target.name, value: e.detail.value } });
  };

  const minBirthDate = format(subYears(new Date(), 100), 'yyyy-MM-dd');
  const maxBirthDate = format(subYears(new Date(), 18), 'yyyy-MM-dd');
  const minAnniversary = format(subYears(new Date(), 82), 'yyyy-MM-dd');
  const maxAnniversary = format(new Date(), 'yyyy-MM-dd');

  return (
    <>
      <IonList>
        <IonItem>
          <IonLabel position="stacked">Title<IonText color='danger'>*</IonText></IonLabel>
          <IonSelect mode='md' name='title' value={contact.title} placeholder='Select' onIonChange={e => onChange(e)}>
            {TITLES.map(title =>
              <IonSelectOption key={title} value={title}>{title}</IonSelectOption>
            )}
          </IonSelect>
        </IonItem>
        <IonItem>
          <IonLabel position="stacked">First Name</IonLabel>
          <IonInput type="text" name="first_name" value={contact.first_name}
            onIonChange={e => onChange(e)}></IonInput>
        </IonItem>
        <IonItem>
          <IonLabel position="stacked">Last Name</IonLabel>
          <IonInput type="text" name="last_name" value={contact.last_name}
            onIonChange={e => onChange(e)}></IonInput>
        </IonItem>
        <IonItem>
          <IonLabel position="stacked">Designation<IonText color='danger'>*</IonText></IonLabel>
          <IonInput type="text" name="designation" value={contact.designation}
            onIonChange={e => onChange(e)}></IonInput>
        </IonItem>
        <IonItem>
          <IonLabel position="stacked">Email<IonText color='danger'>*</IonText></IonLabel>
          <IonInput type="email" name="email_id" value={contact.email_id}
            onIonChange={e => onChange(e)}></IonInput>
        </IonItem>
        <IonItem>
          <IonLabel position="stacked">Phone Number<IonText color='danger'>*</IonText></IonLabel>
          <IonInput type="text" name="phone_number" value={contact.phone_number}
            onIonChange={e => onChange(e)}></IonInput>
        </IonItem>
        <IonItem>
          <IonLabel position="stacked">Birthday</IonLabel>
          <IonInput readonly name="birth_date" value={contact.birth_date ? format(new Date(contact.birth_date), 'dd-MM-yyyy') : 'Select'}
            onClick={e => setShowBirthday(true)}></IonInput>
          {showBirthday && <IonDatetime name='birth_date' min={minBirthDate} max={maxBirthDate} value={contact.birth_date} onIonChange={e => onChange(e)}></IonDatetime>}
        </IonItem>
        <IonItem>
          <IonLabel position="stacked">Anniversary</IonLabel>
          <IonInput readonly name="anniversary" value={contact.anniversary ? format(new Date(contact.anniversary), 'dd-MM-yyyy') : 'Select'}
            onClick={e => setAnniversary(true)}></IonInput>
          {showAnniversary && <IonDatetime name='anniversary' min={minAnniversary} max={maxAnniversary} value={contact.anniversary} onIonChange={e => onChange(e)}></IonDatetime>}
        </IonItem>
        {edit && !isAgency &&
          <>
            <IonItem lines='none'>
              <IonLabel>Change Client</IonLabel>
            </IonItem>
            <Search placeholder='Clients' searchAction={searchClients} />

            {clientLoading && clients === null ? (
              <IonSpinner />
            ) : (
                <IonList>
                  <IonRadioGroup name='client_id' value={(contact as ClientContact).client_id} onIonChange={e => onChange(e)}>
                    {clients.map(client => (
                      <IonItem lines='none' key={client.client_id}>
                        <IonLabel className='ion-text-wrap'>{client.client_name}</IonLabel>
                        <IonRadio mode='md' slot="start" value={client.client_id} />
                      </IonItem>
                    ))}
                  </IonRadioGroup>
                  <IonItem><IonLabel>&nbsp;</IonLabel></IonItem>
                </IonList>
              )}
          </>
        }
        {edit && isAgency &&
          <>
            <IonItem lines='none'>
              <IonLabel>Change Agency</IonLabel>
            </IonItem>
            <Search placeholder='Agencies' searchAction={searchAgencies} />
            {agencyLoading && agencies === null ? (
              <IonSpinner />
            ) : (
                <IonList>
                  <IonRadioGroup name='agency_id' value={(contact as AgencyContact).agency_id} onIonChange={e => onChange(e)}>
                    {agencies.map(agency => (
                      <IonItem lines='none' key={agency.agency_id}>
                        <IonLabel className='ion-text-wrap'>{agency.agency_name}</IonLabel>
                        <IonRadio mode='md' slot="start" value={agency.agency_id} />
                      </IonItem>
                    ))}
                  </IonRadioGroup>
                  <IonItem><IonLabel>&nbsp;</IonLabel></IonItem>
                </IonList>
              )}
          </>
        }

      </IonList>
    </>
  );
};

export default ContactContainer;