import { LOGIN_SUCCESS, LOGIN_FAIL, SEARCH_USERS_SUCCESS, SEARCH_USERS_FAIL, SET_LOGIN_FORM_VALUE, SELECT_USER, DESELECT_USER, RESEND_PASSWORD_FAIL, RESEND_PASSWORD, LOGOUT } from "../actions/types";
import { User, LoginForm } from "../types";
import { UserActionTypes } from "../actions/user";

export interface UserState {
  loading: boolean,
  user_id: string,
  token: string,
  users: User[],
  taggedUsers: User[],
  loginForm: LoginForm
}

const initialState: UserState = {
  loading: true,
  user_id: localStorage.getItem('user_id') || '',
  token: localStorage.getItem('token') || '',
  users: [] as User[],
  taggedUsers: [] as User[],
  loginForm: {} as LoginForm
};

export default function (state = initialState, action: UserActionTypes) {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return { ...state, user_id: action.uid, token: action.token, loginForm: {} as LoginForm, loading: false };
    case SEARCH_USERS_SUCCESS:
      const users: User[] = action.users.filter(user => !state.taggedUsers.find(el => user.user_id === el.user_id)).map(user => ({ ...user, checked: false }));
      return { ...state, users, loading: false };
    case SEARCH_USERS_FAIL:
      return { ...state, users: [] as User[], loading: false };
    case SELECT_USER:
      return {
        ...state, taggedUsers: [...state.taggedUsers, { ...action.user, checked: true }],
        users: state.users.filter(user => user.user_id !== action.user.user_id)
      }
    case DESELECT_USER:
      return {
        ...state, users: [...state.users, { ...action.user, checked: false }],
        taggedUsers: state.taggedUsers.filter(user => user.user_id !== action.user.user_id)
      }
    case LOGIN_FAIL:
    case LOGOUT:
      return { ...state, user_id: '', token: '', loading: false };
    case SET_LOGIN_FORM_VALUE:
      return { ...state, loginForm: { ...state.loginForm, [action.payload.field]: action.payload.value } }
    case RESEND_PASSWORD_FAIL: return { ...state, loginForm: {} as LoginForm };
    case RESEND_PASSWORD:
    default:
      return state;
  }
}
