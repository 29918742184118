import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonButtons, IonBackButton, IonFooter, IonButton } from '@ionic/react';
import React from 'react';
import HoDContainer from '../components/HoDContainer';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { sendHoD } from '../actions/hod';
import { setAlert } from '../actions/alert';
import { RootState } from '../reducers';

const HoD: React.FC = () => {

  const dispatch = useDispatch();
  const user_id = useSelector<RootState, string>(state => state.user.user_id);
  const hod = useSelector<RootState, string>(state => state.hod.hod);

  const history = useHistory();

  const onSubmit = () => {
    if (hod) {
      dispatch(sendHoD(hod, user_id, history));
    } else {
      dispatch(setAlert('Please specify highlights of the day', 'danger'));
    }
  }

  return (
    <IonPage>
      <IonHeader translucent={true} collapse='fade'>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="home" />
          </IonButtons>
          <IonTitle>HOD</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">HOD</IonTitle>
          </IonToolbar>
        </IonHeader>
        <HoDContainer />
      </IonContent>
      <IonFooter>
        <IonButton expand='block' onClick={() =>
          onSubmit()
        }>Submit</IonButton>
      </IonFooter>
    </IonPage>
  );
};

export default HoD;
