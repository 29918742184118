import { IonRouterOutlet } from '@ionic/react';
import React from 'react';
import { Route, Redirect } from 'react-router';
import DsrList from '../pages/DsrList';
import AgencyPage from '../pages/AgencyPage';
import Brand from '../pages/Brand';
import CampaignPage from '../pages/CampaignPage';
import ClientPage from '../pages/ClientPage';
import ContactPage from '../pages/ContactPage';
import DsrDetailPage from '../pages/DsrDetailPage';
import DsrSummary from '../pages/DsrSummary';
import HoD from '../pages/HoD';
import Home from '../pages/Home';
import Login from '../pages/Login';
import NotFound from '../pages/NotFound';
import RegisterCall from '../pages/RegisterCall';
import SearchAgency from '../pages/SearchAgency';
import SearchClient from '../pages/SearchClient';
import SearchUser from '../pages/SearchUser';
import SelectAgencyClient from '../pages/SelectAgencyClient';
import SelectBrand from '../pages/SelectBrand';
import SelectContact from '../pages/SelectContact';
import PrivateRoute from './PrivateRoute';
import ChangePassword from '../pages/ChangePassword';

const AppRoute: React.FC = () => {
  return (
    <IonRouterOutlet id="main">

    <Route exact path='/'>
      <Redirect to='/home' />
    </Route>

    <Route path='/login' exact>
      <Login />
    </Route>

    <PrivateRoute path='/add-brands' exact>
      <Brand />
    </PrivateRoute>

    <PrivateRoute path='/brands/tg/:brand_id' exact>
      <Brand />
    </PrivateRoute>

    <PrivateRoute path='/brands/edit/:brand_id' exact>
      <Brand />
    </PrivateRoute>

    <PrivateRoute path='/brands/:clients' exact>
      <SelectBrand />
    </PrivateRoute>


    <PrivateRoute path='/campaign/:brand_id' exact>
      <CampaignPage />
    </PrivateRoute>


    <PrivateRoute path='/clients/add-contacts' exact>
      <ContactPage />
    </PrivateRoute>

    <PrivateRoute path='/clients/contacts/edit/:contact_id' exact>
      <ContactPage />
    </PrivateRoute>

    <PrivateRoute path='/clients/contacts/:id' exact>
      <SelectContact />
    </PrivateRoute>

    <PrivateRoute path='/clients/add' exact>
      <ClientPage />
    </PrivateRoute>

    <PrivateRoute path='/clients/edit/:client_id' exact>
      <ClientPage />
    </PrivateRoute>

    <PrivateRoute path='/clients' exact>
      <SearchClient />
    </PrivateRoute>

    <PrivateRoute path='/agencies/add' exact>
      <AgencyPage />
    </PrivateRoute>

    <PrivateRoute path='/agencies/add-clients' exact>
      <ClientPage />
    </PrivateRoute>

    <PrivateRoute path='/agencies/clients/:agency_id' exact>
      <SelectAgencyClient />
    </PrivateRoute>


    <PrivateRoute path='/agencies/edit/:agency_id' exact>
      <AgencyPage />
    </PrivateRoute>

    <PrivateRoute path='/agencies/add-contacts' exact>
      <ContactPage />
    </PrivateRoute>

    <PrivateRoute path='/agencies/contacts/edit/:contact_id' exact>
      <ContactPage />
    </PrivateRoute>

    <PrivateRoute path='/agencies/contacts/:id' exact>
      <SelectContact />
    </PrivateRoute>

    <PrivateRoute path='/agencies' exact>
      <SearchAgency />
    </PrivateRoute>

    <PrivateRoute path='/dsrs/:dsr_id' exact>
      <DsrDetailPage />
    </PrivateRoute>

    <PrivateRoute path='/dsrs/summary' exact>
      <DsrSummary />
    </PrivateRoute>

    <PrivateRoute path='/dsrs' exact>
      <DsrList />
    </PrivateRoute>

    <PrivateRoute path='/home' exact>
      <Home />
    </PrivateRoute>

    <PrivateRoute path='/register' exact>
      <RegisterCall />
    </PrivateRoute>

    <PrivateRoute path='/hod' exact>
      <HoD />
    </PrivateRoute>

    <PrivateRoute path='/tag' exact>
      <SearchUser />
    </PrivateRoute>

    <PrivateRoute path='/password-change' exact>
      <ChangePassword />
    </PrivateRoute>

    <Route>
      <NotFound />
    </Route>

  </IonRouterOutlet>
  );
}

export default AppRoute;