import { SET_DSR_DATE, SET_DSR_CLIENT, SET_DSR_CONTACT, SET_MEETING_TYPE, SET_MEETING_SUMMARY, CREATE_DSR_SUMMARY, ADD_DSR, SET_DSR_AGENCY, SET_DSR_AGENCY_CLIENTS, SET_DSR_TAGGED_USERS, TG_UPDATE, RESET_DSR_AGENCY_CLIENTS, RESET_DSR_CONTACTS } from "../actions/types";
import { AlertState } from "./alert";
import { Client, Agency, Dsr, DsrForm, Campaign, ClientContact, AgencyContact, DsrContact, DsrBrand, AoC } from "../types";
import { DsrActionTypes } from "../actions/dsr";

export interface DsrState {
  dsr: Dsr,
  dsrDate: string,
  error: AlertState,
  taggedUsers: number[],
  dsrClient: Client,
  dsrAgency: Agency,
  dsrAgencyClients: Client[],
  dsrContacts: (ClientContact | AgencyContact)[],
  meetingType: string,
  meetingSummary: string,
  tgUpdate: boolean,
  dsrForm: DsrForm
}

const initialState: DsrState = {
  dsr: {} as Dsr,
  dsrDate: '',
  error: {} as AlertState,
  taggedUsers: [],
  dsrClient: {} as Client,
  dsrAgency: {} as Agency,
  dsrAgencyClients: [],
  dsrContacts: [],
  meetingType: '',
  meetingSummary: '',
  tgUpdate: false,
  dsrForm: {} as DsrForm
};

export default function (state = initialState, action: DsrActionTypes) {
  switch (action.type) {
    case SET_DSR_TAGGED_USERS:
      return { ...state, taggedUsers: action.ids };
    case SET_DSR_DATE:
      return { ...state, dsrDate: action.dsrDate };
    case SET_DSR_CLIENT:
      return { ...state, dsrClient: action.client, dsrAgency: {} as Agency, dsrContacts: [] };
    case SET_DSR_AGENCY:
      return { ...state, dsrAgency: action.agency, dsrClient: {} as Client, dsrContacts: [] };
    case SET_MEETING_TYPE:
      return { ...state, meetingType: action.meetingType };
    case SET_MEETING_SUMMARY:
      return { ...state, meetingSummary: action.meetingSummary };
    case ADD_DSR:
      return { ...state, dsr: {} as Dsr, dsrAgency: {} as Agency,
        dsrForm: {} as DsrForm, meetingSummary: '', meetingType: '',
        dsrClient: {} as Client, dsrContacts: [], dsrAgencyClients: [] };
    case SET_DSR_CONTACT:
      if (action.payload.checked) {
        return { ...state, dsrContacts: [...state.dsrContacts, action.payload.contact] }
      }
      if ((action.payload.contact as ClientContact).client_contact_id) {
        return { ...state, dsrContacts: (state.dsrContacts as ClientContact[]).filter(contact => contact.client_contact_id !== (action.payload.contact as ClientContact).client_contact_id) };
      }
      return { ...state, dsrContacts: (state.dsrContacts as AgencyContact[]).filter(contact => contact.agency_contact_id !== (action.payload.contact as AgencyContact).agency_contact_id) };
    case SET_DSR_AGENCY_CLIENTS:
      if (action.payload.checked) {
        return { ...state, dsrAgencyClients: [...state.dsrAgencyClients, action.payload.client] }
      }
      return {
        ...state, dsrAgencyClients:
          state.dsrAgencyClients.filter(client => client.client_id !== action.payload.client.client_id)
      };
    case TG_UPDATE:
      return { ...state, tgUpdate: action.tgUpdate };
    case CREATE_DSR_SUMMARY:
      return { ...state, dsrForm: createDsrForm(state, action.payload.user_id, action.payload.campaigns) };
    case RESET_DSR_AGENCY_CLIENTS:
      return { ...state, dsrAgencyClients: [] }
    case RESET_DSR_CONTACTS:
        return { ...state, dsrContacts: [] }
    default:
      return state;
  }
}

const createDsrForm = (dsr: any, user_id: string, campaigns: Campaign[]) => {
  const dsrForm = {} as DsrForm;
  dsrForm.tagged_users = dsr.taggedUsers;
  dsrForm.client_id = dsr.dsrClient && dsr.dsrClient.client_id;
  dsrForm.agency_id = dsr.dsrAgency && dsr.dsrAgency.agency_id;
  dsrForm.clients = dsr.dsrAgencyClients;
  dsrForm.dsr_date = dsr.dsrDate;
  dsrForm.meeting_summary = dsr.meetingSummary;
  dsrForm.meeting_type = dsr.meetingType;

  const contacts: DsrContact[] = [];
  dsr.dsrContacts.forEach((item: any) => {
    const contact = {} as any;
    if (dsr.dsrClient && dsr.dsrClient.client_id) {
      contact.client_agency = 1;
      contact.contact_id = item.client_contact_id;
    } else {
      contact.client_agency = 2;
      contact.contact_id = item.agency_contact_id;
    }
    contacts.push(contact);
  });

  dsrForm.contacts = contacts;

  const brands: DsrBrand[] = [];

  campaigns.forEach((item: any) => {
    const brand = {} as any;
    brand.brand_id = item.brand_id;
    brand.client_id = item.client_id;
    brand.brief = item.mediaBrief;
    brand.key_market = item.keyMarkets && item.keyMarkets.map((el: any) => el.name).join(', ');
    if (brand.key_market) {
      brand.key_market = item.otherMarket ? `${brand.key_market},${item.otherMarket}` : brand.key_market;
    } else {
      brand.key_market = item.otherMarket;
    }
    brand.market_definition = item.marketDefinition;
    brand.campaign_date = item.campaignDate;
    brand.channels = item.genres.map((el: any) => el.name).join(', ');
    const aocs: AoC[] = [];

    item.genres.forEach((genre: any) => {
      const aoc = {} as any;
      aoc.channel_id = genre.id;
      aoc.channel_name = genre.name;
      aoc.other_info = genre.concern.otherInfo && genre.concern.otherInfo.join(', ');
      if (aoc.other_info) {
        aoc.other_info = genre.concern.others ? `${aoc.other_info},${genre.concern.others}` : aoc.other_info;
      } else {
        aoc.other_info = genre.concern.others;
      }
      aoc.part_of_campaign = genre.concern.partOfCampaign;
      aocs.push(aoc);
    });

    brand.areas_of_concerns = aocs;
    brands.push(brand);
  });

  dsrForm.brands = brands;
  dsrForm.user_id = user_id;
  return dsrForm;
}