import axios from 'axios';
import { History } from 'history';
import { API_ENDPOINT } from '../constants';
import { setAlert } from './alert';
import { GET_CONTACTS, CONTACT_FAIL, GET_CONTACT, ADD_CONTACT, SET_CONTACT_FORM_VALUE, UPDATE_AGENCY_CONTACT, UPDATE_CLIENT_CONTACT, RESET_CONTACT, ADD_CLIENT_CONTACT_FAIL, ADD_AGENCY_CONTACT_FAIL, RESET_ADD_CONTACT_FAIL } from './types';
import { ClientContact, AgencyContact } from '../types';
import { AppThunk } from '../store';

interface GetContactsAction {
  type: typeof GET_CONTACTS,
  contacts: ClientContact[] | AgencyContact[]
}

interface GetContactAction {
  type: typeof GET_CONTACT,
  contact: ClientContact | AgencyContact
}

interface AddContactAction {
  type: typeof ADD_CONTACT,
  contact: ClientContact | AgencyContact
}

interface UpdateClientContactAction {
  type: typeof UPDATE_CLIENT_CONTACT,
  contact: ClientContact,
  client_id: number
}

interface UpdateAgencyContactAction {
  type: typeof UPDATE_AGENCY_CONTACT,
  contact: AgencyContact,
  agency_id: number
}

interface ResetContactAction {
  type: typeof RESET_CONTACT
}

interface ContactFailAction {
  type: typeof CONTACT_FAIL
}

interface AddClientContactFailAction {
  type: typeof ADD_CLIENT_CONTACT_FAIL,
  contact: ClientContact
}

interface AddAgencyContactFailAction {
  type: typeof ADD_AGENCY_CONTACT_FAIL,
  contact: AgencyContact
}

interface SetContactFormValueAction {
  type: typeof SET_CONTACT_FORM_VALUE,
  payload: { field: string, value: string }
}

interface ResetAddContactFailAction {
  type: typeof RESET_ADD_CONTACT_FAIL
}

export type ContactActionTypes = GetContactsAction | GetContactAction | AddContactAction | UpdateClientContactAction |
  UpdateAgencyContactAction | ResetContactAction | ContactFailAction | AddClientContactFailAction |
  AddAgencyContactFailAction | SetContactFormValueAction | ResetAddContactFailAction;

const getContactsAction = (contacts: ClientContact[] | AgencyContact[]): ContactActionTypes => {
  return ({
    type: GET_CONTACTS,
    contacts
  })
}

const getContactAction = (contact: ClientContact | AgencyContact): ContactActionTypes => {
  return ({
    type: GET_CONTACT,
    contact
  })
}

const addContactAction = (contact: ClientContact | AgencyContact): ContactActionTypes => {
  return ({
    type: ADD_CONTACT,
    contact
  })
}

const updateClientContactAction = (contact: ClientContact, client_id: number): ContactActionTypes => {
  return ({
    type: UPDATE_CLIENT_CONTACT,
    contact,
    client_id
  })
}

const updateAgencyContactAction = (contact: AgencyContact, agency_id: number): ContactActionTypes => {
  return ({
    type: UPDATE_AGENCY_CONTACT,
    contact,
    agency_id
  })
}

export const setContactFormValueAction = (field: string, value: string): ContactActionTypes => {
  return ({
    type: SET_CONTACT_FORM_VALUE,
    payload: { field, value }
  })
}

export const resetContactAction = (): ContactActionTypes => {
  return ({
    type: RESET_CONTACT
  })
}

const contactFailAction = (): ContactActionTypes => {
  return ({
    type: CONTACT_FAIL
  })
}

const addClientContactFailAction = (contact: ClientContact): ContactActionTypes => {
  return ({
    type: ADD_CLIENT_CONTACT_FAIL,
    contact
  })
}

const addAgencyContactFailAction = (contact: AgencyContact): ContactActionTypes => {
  return ({
    type: ADD_AGENCY_CONTACT_FAIL,
    contact
  })
}

export const resetAddContactFailAction = (): ContactActionTypes => {
  return({
    type: RESET_ADD_CONTACT_FAIL
  })
}

export const getContacts = (id: string, agency = false): AppThunk => async dispatch => {

  const params = agency ? { agency_id: id } : { client_id: id };
  const urlPath = agency ? 'agency' : 'client';

  try {
    const res = await axios.get(`${API_ENDPOINT}/${urlPath}_contacts/index`, {
      params
    });
    if (res.data.status) {
      dispatch(getContactsAction(res.data.message));
    } else {
      dispatch(setAlert(res.data.message, 'danger'));
    }
  } catch (err) {
    console.log(err);
    if (err.response) {
      dispatch(setAlert(err.response.statusText, 'danger'));
      dispatch(contactFailAction());
    } else {
      dispatch(setAlert('Unknown error occurred. Plesae retry', 'danger'));
    }
  }
};

export const getContact = (contact_id?: number, agency = false): AppThunk => async dispatch => {

  const params = agency ? { agency_contact_id: contact_id } : { client_contact_id: contact_id };
  const urlPath = agency ? 'agency' : 'client';

  try {
    const res = await axios.get(`${API_ENDPOINT}/${urlPath}_contacts/edit`, {
      params
    });
    if (res.data.status) {
      dispatch(getContactAction(res.data.message));
    } else {
      dispatch(setAlert(res.data.message, 'danger'));
    }
  } catch (err) {
    console.log(err);
    if (err.response) {
      dispatch(setAlert(err.response.statusText, 'danger'));
      dispatch(contactFailAction());
    } else {
      dispatch(setAlert('Unknown error occurred. Plesae retry', 'danger'));
    }
  }
};

export const addContact = (contact: ClientContact | AgencyContact, history: History, agency = false): AppThunk => async dispatch => {

  const urlPath = agency ? 'agency' : 'client';

  const config = {
    headers: {
      'Content-type': 'application/json'
    }
  };
  try {
    const res = await axios.post(`${API_ENDPOINT}/${urlPath}_contacts/create`, contact, config);

    if (res.data.status) {
      dispatch(addContactAction(res.data.message));
      dispatch(setAlert('New contact added', 'success'));
      history.goBack();
    } else {
      dispatch(setAlert(res.data.message, 'danger'));
      if (agency) {
        dispatch(addAgencyContactFailAction(res.data.contact));
      } else {
        dispatch(addClientContactFailAction(res.data.contact));
      }
    }
  } catch (err) {
    console.log(err);
    if (err.msg) {
      dispatch(setAlert(err.msg, 'danger'));
    } else {
      dispatch(setAlert('Unknown error occurred. Plesae retry', 'danger'));
    }
    dispatch({ type: CONTACT_FAIL, payload: {} });
  }
};

export const updateContact = (contact: ClientContact | AgencyContact, history: History, id: number, agency = false): AppThunk => async dispatch => {

  const urlPath = agency ? 'agency' : 'client';

  const config = {
    headers: {
      'Content-type': 'application/json'
    }
  };
  try {
    const res = await axios.post(`${API_ENDPOINT}/${urlPath}_contacts/update`, contact, config);
    const updatedContact = res.data.message;
    if (res.data.status) {
      if (agency) {
        dispatch(updateAgencyContactAction(updatedContact, id));
      } else {
        dispatch(updateClientContactAction(updatedContact, id));
      }
      dispatch(setAlert('Contact updated', 'success'));
      history.goBack();
    } else {
      dispatch(setAlert(res.data.message, 'danger'));
      dispatch(contactFailAction());
    }
  } catch (err) {
    console.log(err);
    if (err.msg) {
      dispatch(setAlert(err.msg, 'danger'));
    } else {
      dispatch(setAlert('Unknown error occurred. Plesae retry', 'danger'));
    }
    dispatch(contactFailAction());
  }
};