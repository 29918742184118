import React from 'react';
import { IonItem, IonButton, IonIcon, IonSpinner, IonList, IonLabel, IonRadio, IonRadioGroup, IonItemSliding, IonItemOptions, IonItemOption, IonListHeader, IonText } from '@ionic/react';
import { searchClients } from '../actions/client';
import { setDsrClientAction } from '../actions/dsr';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { createOutline, personAdd } from 'ionicons/icons';
import Search from './Search';
import { RootState } from '../reducers';
import { Client } from '../types';

const SearchClientContainer: React.FC = () => {

  const dispatch = useDispatch();
  const dsrClient = useSelector<RootState, Client>(state => state.dsr.dsrClient);
  const loading = useSelector<RootState, boolean>(state => state.client.loading);
  const clients = useSelector<RootState, Client[]>(state => state.client.clients);
  const recentClients = useSelector<RootState, Client[]>(state => state.client.recentClients);

  const history = useHistory();

  const onClientSelected = (id: number) => {
    const selectedClient = clients.find(client => client.client_id === id);
    selectedClient && dispatch(setDsrClientAction(selectedClient));
  }

  const onRecentClientSelected = (id: number) => {
    const selectedClient = recentClients.find(client => client.client_id === id);
    selectedClient && dispatch(setDsrClientAction(selectedClient));
  }

  return (
    <>
      <Search placeholder='Clients' searchAction={searchClients}></Search>
      <IonItem lines='none'>
        <IonButton fill='clear' onClick={() => history.push('/clients/add')}><IonIcon slot='start' icon={personAdd}></IonIcon>Add New Client</IonButton>
      </IonItem>
      {loading && clients === null ? (
        <IonSpinner />
      ) : (
          <IonList className="ion-padding">
            <IonRadioGroup value={dsrClient?.client_id} onIonChange={e => onClientSelected(e.detail.value)}>
              {clients.map(client => (
                <IonItemSliding key={client.client_id}>
                  <IonItemOptions side="end">
                    <IonItemOption onClick={() => history.push(`/clients/edit/${client.client_id}`)}>
                      <IonIcon slot="start" icon={createOutline}></IonIcon>
                Edit</IonItemOption>
                  </IonItemOptions>
                  <IonItem lines='none'>
                    <IonLabel className='ion-text-wrap'>{client.client_name}</IonLabel>
                    <IonRadio mode='md' slot="start" value={client.client_id} />
                  </IonItem>
                </IonItemSliding>
              ))}
            </IonRadioGroup>
          </IonList>
        )}
      <IonList lines='none' className="ion-padding">
        <IonListHeader>
          <IonLabel><IonText><h2>Recent Clients</h2></IonText></IonLabel>
        </IonListHeader>
        <IonRadioGroup value={dsrClient?.client_id} onIonChange={e => onRecentClientSelected(e.detail.value)}>
          {recentClients.map(client => (
            <IonItemSliding key={client.client_id}>
              <IonItemOptions side="end">
                <IonItemOption onClick={() => history.push(`/clients/edit/${client.client_id}`)}>
                  <IonIcon slot="start" icon={createOutline}></IonIcon>
                Edit</IonItemOption>
              </IonItemOptions>
              <IonItem>
                <IonLabel className='ion-text-wrap'>{client.client_name}</IonLabel>
                <IonRadio mode='md' slot="start" value={client.client_id} />
              </IonItem>
            </IonItemSliding>
          ))}
        </IonRadioGroup>
      </IonList>
    </>
  );
};

export default SearchClientContainer;